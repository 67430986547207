import React, { useState } from 'react';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db, auth } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import slugify from 'slugify';

const RegisterRestaurant: React.FC = () => {
  const [name, setName] = useState('');
  const [shortCode, setShortCode] = useState('');
  const [description, setDescription] = useState('');
  const [cuisine, setCuisine] = useState<string[]>([]);
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const generateShortCode = (businessName: string) => {
    const slug = slugify(businessName, { lower: true, strict: true });
    const prefix = slug.slice(0, 4).toUpperCase();
    const randomNum = Math.floor(Math.random() * 900) + 100;
    return `${prefix}${randomNum}`;
  };

  const validateShortCode = async (code: string) => {
    const restaurantDoc = await getDoc(doc(db, 'restaurants', code));
    return !restaurantDoc.exists();
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!auth.currentUser) {
      toast.error('Please sign in to register a restaurant');
      return;
    }

    setLoading(true);
    try {
      let validShortCode = shortCode;
      if (!validShortCode) {
        validShortCode = generateShortCode(name);
        while (!(await validateShortCode(validShortCode))) {
          validShortCode = generateShortCode(name);
        }
      }

      const restaurantData = {
        ownerId: auth.currentUser.uid,
        name,
        shortCode: validShortCode,
        description,
        businessStatus: 'pending',
        cuisine: cuisine.filter(c => c.trim() !== ''),
        address,
        phone,
        email: auth.currentUser.email,
        openingHours: {
          monday: { open: '09:00', close: '17:00', isClosed: false },
          tuesday: { open: '09:00', close: '17:00', isClosed: false },
          wednesday: { open: '09:00', close: '17:00', isClosed: false },
          thursday: { open: '09:00', close: '17:00', isClosed: false },
          friday: { open: '09:00', close: '17:00', isClosed: false },
          saturday: { open: '10:00', close: '15:00', isClosed: false },
          sunday: { open: '10:00', close: '15:00', isClosed: true },
        },
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };

      await setDoc(doc(db, 'restaurants', validShortCode), restaurantData);
      
      // Update user document with restaurant info
      await setDoc(doc(db, 'users', auth.currentUser.uid), {
        restaurantId: validShortCode,
        userType: 'Business',
        businessStatus: 'pending'
      }, { merge: true });

      toast.success('Restaurant registered successfully!');
      navigate(`/restaurant/${validShortCode}/manage`);
    } catch (error) {
      console.error('Error registering restaurant:', error);
      toast.error('Failed to register restaurant. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-6">
      <h2 className="text-2xl font-bold mb-6">Register Your Restaurant</h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-medium mb-1">Restaurant Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="w-full p-2 rounded bg-gray-700 border border-gray-600 focus:border-blue-500"
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">
            Short Code (Optional - will be generated if left empty)
          </label>
          <input
            type="text"
            value={shortCode}
            onChange={(e) => setShortCode(e.target.value.toUpperCase())}
            maxLength={7}
            pattern="[A-Z0-9]+"
            className="w-full p-2 rounded bg-gray-700 border border-gray-600 focus:border-blue-500"
          />
          <p className="text-sm text-gray-400 mt-1">
            Maximum 7 characters, letters and numbers only
          </p>
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Description</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
            rows={3}
            className="w-full p-2 rounded bg-gray-700 border border-gray-600 focus:border-blue-500"
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">
            Cuisine Types (comma-separated)
          </label>
          <input
            type="text"
            value={cuisine.join(', ')}
            onChange={(e) => setCuisine(e.target.value.split(','))}
            placeholder="Italian, Pizza, Pasta"
            required
            className="w-full p-2 rounded bg-gray-700 border border-gray-600 focus:border-blue-500"
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Address</label>
          <input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
            className="w-full p-2 rounded bg-gray-700 border border-gray-600 focus:border-blue-500"
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Phone</label>
          <input
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
            className="w-full p-2 rounded bg-gray-700 border border-gray-600 focus:border-blue-500"
          />
        </div>

        <button
          type="submit"
          disabled={loading}
          className="w-full bg-gradient-brand hover:opacity-90 text-white font-medium py-2 px-4 rounded transition-opacity disabled:opacity-50"
        >
          {loading ? 'Registering...' : 'Register Restaurant'}
        </button>
      </form>
    </div>
  );
};

export default RegisterRestaurant;