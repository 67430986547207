import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

interface UserData {
  firstName?: string;
  lastName?: string;
  businessName?: string;
  email: string;
  userType: 'Individual' | 'Business';
  businessStatus?: 'pending' | 'approved' | 'rejected';
  memberSince: string;
}

const Profile: React.FC = () => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      if (auth.currentUser) {
        try {
          const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
          if (userDoc.exists()) {
            setUserData(userDoc.data() as UserData);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchUserData();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (!userData) {
    return (
      <div className="text-center text-red-500">
        Error loading profile data
      </div>
    );
  }

  const renderBusinessStatus = () => {
    if (userData.userType !== 'Business') return null;

    const statusColors = {
      pending: 'text-yellow-500',
      approved: 'text-green-500',
      rejected: 'text-red-500'
    };

    return (
      <div className="mb-2">
        <strong>Business Status: </strong>
        <span className={statusColors[userData.businessStatus || 'pending']}>
          {userData.businessStatus?.charAt(0).toUpperCase() + userData.businessStatus?.slice(1)}
        </span>
      </div>
    );
  };

  return (
    <div className="max-w-md mx-auto">
      <h2 className="text-2xl font-bold mb-6">Profile</h2>
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg space-y-4">
        <div className="flex items-center justify-between">
          <span className="text-gray-400">Account Type</span>
          <span className="px-3 py-1 bg-blue-500 text-white rounded-full text-sm">
            {userData.userType}
          </span>
        </div>

        {userData.userType === 'Business' ? (
          <div className="mb-2">
            <strong>Business Name: </strong>
            {userData.businessName}
          </div>
        ) : (
          <>
            <div className="mb-2">
              <strong>Name: </strong>
              {userData.firstName} {userData.lastName}
            </div>
          </>
        )}

        {renderBusinessStatus()}

        <div className="mb-2">
          <strong>Email: </strong>
          {userData.email}
        </div>

        <div>
          <strong>Member Since: </strong>
          {new Date(userData.memberSince).toLocaleDateString()}
        </div>
      </div>
    </div>
  );
};

export default Profile;