import React, { useState, useEffect, useRef } from 'react';
import { Send, DollarSign } from 'lucide-react';
import { collection, query, where, getDocs, orderBy, startAt, endAt, limit, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import RestaurantMenu from './restaurant/RestaurantMenu';
import toast from 'react-hot-toast';

interface ActionsProps {
  onSend: (amount: number, recipientEmail: string) => Promise<void>;
  onRequest: (amount: number, fromEmail: string) => Promise<void>;
  currentUserEmail: string;
}

interface User {
  id: string;
  email: string;
  firstName?: string;
  lastName?: string;
  businessName?: string;
  userType?: 'Individual' | 'Business';
  restaurantId?: string;
}

interface MenuItem {
  id: string;
  name: string;
  price: number;
  quantity: number;
}

const Actions: React.FC<ActionsProps> = ({ onSend, onRequest, currentUserEmail }) => {
  const [amount, setAmount] = useState('');
  const [email, setEmail] = useState('');
  const [actionType, setActionType] = useState<'send' | 'request'>('send');
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState<MenuItem[]>([]);
  const [showMenu, setShowMenu] = useState(false);
  const [selectedRestaurant, setSelectedRestaurant] = useState<any>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const searchUsers = async (searchTerm: string) => {
    if (!searchTerm || searchTerm.length < 2) {
      setFilteredUsers([]);
      setShowDropdown(false);
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const usersRef = collection(db, 'users');
      const q = query(
        usersRef,
        orderBy('email'),
        startAt(searchTerm.toLowerCase()),
        endAt(searchTerm.toLowerCase() + '\uf8ff'),
        limit(5)
      );

      const snapshot = await getDocs(q);
      const users: User[] = [];
      
      snapshot.forEach((doc) => {
        const data = doc.data();
        if (data.email !== currentUserEmail) {
          users.push({
            id: doc.id,
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            businessName: data.businessName,
            userType: data.userType,
            restaurantId: data.restaurantId
          });
        }
      });

      setFilteredUsers(users);
      setShowDropdown(users.length > 0);
    } catch (err) {
      console.error('Error searching users:', err);
      setError('Failed to search users. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    setError(null);
    searchUsers(value);
  };

  const handleSelectUser = async (user: User) => {
    setEmail(user.email);
    setShowDropdown(false);
    setError(null);

    // If the selected user is a business, fetch and show their menu
    if (user.userType === 'Business' && user.restaurantId) {
      try {
        const restaurantDoc = await getDoc(doc(db, 'restaurants', user.restaurantId));
        if (restaurantDoc.exists()) {
          setSelectedRestaurant({ id: user.restaurantId, ...restaurantDoc.data() });
          setShowMenu(true);
        }
      } catch (err) {
        console.error('Error fetching restaurant:', err);
        toast.error('Failed to load restaurant menu');
      }
    } else {
      setShowMenu(false);
      setSelectedRestaurant(null);
    }

    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleMenuItemsSelected = (items: MenuItem[]) => {
    setSelectedItems(items);
    const total = items.reduce((sum, item) => sum + (item.price * item.quantity), 0);
    setAmount(total.toFixed(2));
    setShowMenu(false);
  };

  const handleAction = async () => {
    const numAmount = parseFloat(amount);
    if (!numAmount || numAmount <= 0) {
      setError('Please enter a valid amount');
      return;
    }

    if (!email) {
      setError('Please select a user');
      return;
    }

    const selectedUser = filteredUsers.find(user => user.email === email);
    if (!selectedUser) {
      setError('Please select a valid user from the list');
      return;
    }

    // For business users, require menu items
    if (selectedUser.userType === 'Business' && selectedItems.length === 0) {
      setError('Please select items from the menu before sending money');
      return;
    }

    setError(null);
    try {
      if (actionType === 'send') {
        await onSend(numAmount, email);
      } else {
        await onRequest(numAmount, email);
      }
      setAmount('');
      setEmail('');
      setFilteredUsers([]);
      setSelectedItems([]);
    } catch (error) {
      console.error('Error performing action:', error);
      toast.error('Failed to complete the transaction. Please try again.');
    }
  };

  return (
    <div className="my-8">
      {error && (
        <div className="bg-red-500/10 border border-red-500 text-red-500 px-4 py-2 rounded mb-4">
          {error}
        </div>
      )}
      
      <div className="space-y-4">
        <div className="relative" ref={dropdownRef}>
          <label htmlFor="email" className="block text-sm font-medium mb-1">
            {actionType === 'send' ? "Recipient" : "Request From"}
          </label>
          <input
            ref={inputRef}
            id="email"
            type="text"
            value={email}
            onChange={handleEmailChange}
            placeholder={`Search by email${actionType === 'send' ? ' to send to' : ' to request from'}`}
            className="w-full p-2 rounded bg-clkk-gray-800 border border-clkk-gray-700 focus:border-clkk-blue focus:ring-1 focus:ring-clkk-blue"
            autoComplete="off"
          />

          {showDropdown && (
            <div className="absolute z-10 w-full mt-1 bg-clkk-gray-800 border border-clkk-gray-700 rounded-md shadow-lg max-h-60 overflow-auto">
              {isLoading ? (
                <div className="p-2 text-gray-400">Searching...</div>
              ) : filteredUsers.length > 0 ? (
                <ul>
                  {filteredUsers.map((user) => (
                    <li
                      key={user.id}
                      onClick={() => handleSelectUser(user)}
                      className="p-2 hover:bg-clkk-gray-700 cursor-pointer flex flex-col"
                    >
                      <span className="font-medium">
                        {user.userType === 'Business' ? user.businessName : `${user.firstName} ${user.lastName}`}
                      </span>
                      <span className="text-sm text-gray-400">{user.email}</span>
                      {user.userType === 'Business' && (
                        <span className="text-xs text-blue-400">Business Account</span>
                      )}
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="p-2 text-gray-400">No users found</div>
              )}
            </div>
          )}
        </div>

        {showMenu && selectedRestaurant && (
          <RestaurantMenu
            restaurant={selectedRestaurant}
            onItemsSelected={handleMenuItemsSelected}
            onClose={() => setShowMenu(false)}
          />
        )}

        <div>
          <label htmlFor="amount" className="block text-sm font-medium mb-1">Amount</label>
          <div className="relative">
            <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400">$</span>
            <input
              id="amount"
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder="0.00"
              min="0.01"
              step="0.01"
              className="w-full pl-8 pr-4 py-2 rounded bg-clkk-gray-800 border border-clkk-gray-700 focus:border-clkk-blue focus:ring-1 focus:ring-clkk-blue"
              readOnly={selectedItems.length > 0}
            />
          </div>
        </div>

        {selectedItems.length > 0 && (
          <div className="bg-clkk-gray-800 p-4 rounded">
            <h3 className="font-medium mb-2">Selected Items:</h3>
            <ul className="space-y-2">
              {selectedItems.map((item) => (
                <li key={item.id} className="flex justify-between">
                  <span>{item.name} x{item.quantity}</span>
                  <span>${(item.price * item.quantity).toFixed(2)}</span>
                </li>
              ))}
            </ul>
          </div>
        )}

        <button
          onClick={handleAction}
          className="w-full bg-gradient-brand hover:opacity-90 p-3 rounded flex items-center justify-center transition-opacity disabled:opacity-50 disabled:cursor-not-allowed"
          disabled={!email || !amount || email === currentUserEmail}
        >
          {actionType === 'send' ? (
            <>
              <Send size={20} className="mr-2" />
              Send Money
            </>
          ) : (
            <>
              <DollarSign size={20} className="mr-2" />
              Request Money
            </>
          )}
        </button>

        <button
          onClick={() => {
            setActionType(actionType === 'send' ? 'request' : 'send');
            setError(null);
          }}
          className="w-full bg-clkk-gray-800 hover:bg-clkk-gray-700 p-3 rounded flex items-center justify-center transition-colors"
        >
          Switch to {actionType === 'send' ? 'Request' : 'Send'}
        </button>
      </div>
    </div>
  );
};

export default Actions;