import React, { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';
import { MenuItem, Restaurant } from '../../types/restaurant';
import { ShoppingCart, X } from 'lucide-react';
import toast from 'react-hot-toast';

interface RestaurantMenuProps {
  restaurant: Restaurant;
  onItemsSelected: (items: { id: string; name: string; price: number; quantity: number }[]) => void;
  onClose: () => void;
}

const RestaurantMenu: React.FC<RestaurantMenuProps> = ({ restaurant, onItemsSelected, onClose }) => {
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [cart, setCart] = useState<{[key: string]: number}>({});

  useEffect(() => {
    const q = query(
      collection(db, 'restaurants', restaurant.id, 'menuItems'),
      where('isAvailable', '==', true)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const items: MenuItem[] = [];
      snapshot.forEach((doc) => {
        items.push({ id: doc.id, ...doc.data() } as MenuItem);
      });
      setMenuItems(items);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [restaurant.id]);

  const addToCart = (itemId: string) => {
    setCart(prev => ({
      ...prev,
      [itemId]: (prev[itemId] || 0) + 1
    }));
  };

  const removeFromCart = (itemId: string) => {
    setCart(prev => {
      const newCart = { ...prev };
      if (newCart[itemId] > 1) {
        newCart[itemId]--;
      } else {
        delete newCart[itemId];
      }
      return newCart;
    });
  };

  const handleConfirm = () => {
    const selectedItems = Object.entries(cart).map(([itemId, quantity]) => {
      const item = menuItems.find(i => i.id === itemId);
      if (!item) throw new Error('Item not found');
      return {
        id: itemId,
        name: item.name,
        price: item.price,
        quantity
      };
    });
    onItemsSelected(selectedItems);
  };

  if (loading) {
    return (
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  const categories = [...new Set(menuItems.map(item => item.category))];

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-clkk-dark border border-clkk-gray-800 rounded-lg w-full max-w-2xl max-h-[80vh] overflow-auto">
        <div className="sticky top-0 bg-clkk-dark border-b border-clkk-gray-800 p-4 flex justify-between items-center">
          <h2 className="text-xl font-bold">{restaurant.name} Menu</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-white">
            <X size={24} />
          </button>
        </div>

        <div className="p-4 space-y-6">
          {categories.map(category => (
            <div key={category}>
              <h3 className="text-lg font-semibold mb-3">{category}</h3>
              <div className="space-y-3">
                {menuItems
                  .filter(item => item.category === category)
                  .map(item => (
                    <div
                      key={item.id}
                      className="bg-clkk-gray-800 p-3 rounded-lg flex justify-between items-center"
                    >
                      <div>
                        <h4 className="font-medium">{item.name}</h4>
                        <p className="text-sm text-gray-400">{item.description}</p>
                        <p className="mt-1 font-medium">${item.price.toFixed(2)}</p>
                      </div>
                      <div className="flex items-center space-x-3">
                        {cart[item.id] ? (
                          <>
                            <button
                              onClick={() => removeFromCart(item.id)}
                              className="w-8 h-8 flex items-center justify-center bg-red-500 text-white rounded-full"
                            >
                              -
                            </button>
                            <span className="w-8 text-center">{cart[item.id]}</span>
                          </>
                        ) : null}
                        <button
                          onClick={() => addToCart(item.id)}
                          className="w-8 h-8 flex items-center justify-center bg-gradient-brand text-white rounded-full"
                        >
                          +
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>

        {Object.keys(cart).length > 0 && (
          <div className="sticky bottom-0 bg-clkk-dark border-t border-clkk-gray-800 p-4 flex justify-between items-center">
            <div className="flex items-center space-x-3">
              <ShoppingCart size={20} />
              <span>
                {Object.values(cart).reduce((a, b) => a + b, 0)} items
              </span>
            </div>
            <button
              onClick={handleConfirm}
              className="bg-gradient-brand hover:opacity-90 text-white px-6 py-2 rounded"
            >
              Confirm Selection
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default RestaurantMenu;