import React, { useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { Restaurant } from '../../types/restaurant';
import { useNavigate } from 'react-router-dom';
import { Search } from 'lucide-react';
import toast from 'react-hot-toast';

const RestaurantSearch: React.FC = () => {
  const [shortCode, setShortCode] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!shortCode) return;

    setLoading(true);
    try {
      const restaurantDoc = await getDoc(doc(db, 'restaurants', shortCode.toUpperCase()));
      
      if (!restaurantDoc.exists()) {
        toast.error('Restaurant not found. Please check the code and try again.');
        return;
      }

      const restaurant = restaurantDoc.data() as Restaurant;
      if (restaurant.businessStatus !== 'approved') {
        toast.error('This restaurant is not currently active.');
        return;
      }

      navigate(`/restaurant/${shortCode}`);
    } catch (error) {
      console.error('Error searching restaurant:', error);
      toast.error('Failed to search restaurant. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto p-6">
      <h2 className="text-2xl font-bold mb-6 text-center">Find a Restaurant</h2>
      <form onSubmit={handleSearch} className="space-y-4">
        <div className="relative">
          <input
            type="text"
            value={shortCode}
            onChange={(e) => setShortCode(e.target.value.toUpperCase())}
            placeholder="Enter restaurant code (e.g., PIZZA123)"
            maxLength={7}
            className="w-full p-4 pl-12 rounded-lg bg-gray-700 border border-gray-600 focus:border-blue-500"
          />
          <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
        </div>
        <button
          type="submit"
          disabled={loading || !shortCode}
          className="w-full bg-gradient-brand hover:opacity-90 text-white font-medium py-3 px-4 rounded transition-opacity disabled:opacity-50"
        >
          {loading ? 'Searching...' : 'Search Restaurant'}
        </button>
      </form>
    </div>
  );
};

export default RestaurantSearch;