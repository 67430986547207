import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db, auth } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { Store, Settings } from 'lucide-react';
import RegisterRestaurant from './RegisterRestaurant';
import toast from 'react-hot-toast';

const BusinessDashboard: React.FC = () => {
  const [restaurant, setRestaurant] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRestaurant = async () => {
      if (!auth.currentUser) return;

      try {
        const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
        if (!userDoc.exists()) {
          setLoading(false);
          return;
        }

        const userData = userDoc.data();
        if (userData.restaurantId) {
          const restaurantDoc = await getDoc(doc(db, 'restaurants', userData.restaurantId));
          if (restaurantDoc.exists()) {
            setRestaurant({ id: restaurantDoc.id, ...restaurantDoc.data() });
          }
        }
      } catch (error) {
        console.error('Error fetching restaurant:', error);
        toast.error('Failed to load restaurant data');
      } finally {
        setLoading(false);
      }
    };

    fetchRestaurant();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (!restaurant) {
    return <RegisterRestaurant />;
  }

  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="mb-8">
        <h1 className="text-3xl font-bold mb-2">{restaurant.name}</h1>
        <p className="text-gray-400">Restaurant Dashboard</p>
      </div>

      <div className="grid gap-6 md:grid-cols-2">
        <button
          onClick={() => navigate(`/restaurant/${restaurant.shortCode}/manage`)}
          className="bg-clkk-gray-800 hover:bg-clkk-gray-700 p-6 rounded-lg text-left transition-colors"
        >
          <Store size={24} className="mb-4" />
          <h3 className="text-lg font-medium mb-2">Menu Management</h3>
          <p className="text-gray-400">Add, edit, and manage your menu items</p>
        </button>

        <button
          onClick={() => navigate('/settings')}
          className="bg-clkk-gray-800 hover:bg-clkk-gray-700 p-6 rounded-lg text-left transition-colors"
        >
          <Settings size={24} className="mb-4" />
          <h3 className="text-lg font-medium mb-2">Restaurant Settings</h3>
          <p className="text-gray-400">Update your restaurant information</p>
        </button>
      </div>
    </div>
  );
};

export default BusinessDashboard;