import React, { useEffect, useState } from 'react';
import { collection, query, where, onSnapshot, orderBy, limit, Timestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { ArrowUpRight, ArrowDownLeft, Clock } from 'lucide-react';

interface Transaction {
  id: string;
  type: 'send' | 'receive' | 'request';
  amount: number;
  recipientEmail?: string;
  senderEmail?: string;
  fromEmail?: string;
  status?: 'pending' | 'approved' | 'rejected';
  timestamp: Timestamp | null;
}

interface TransactionHistoryProps {
  userId: string;
}

const TransactionHistory: React.FC<TransactionHistoryProps> = ({ userId }) => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [pendingRequests, setPendingRequests] = useState<Transaction[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!userId) {
      setLoading(false);
      return;
    }

    const unsubscribers: (() => void)[] = [];

    try {
      // Transactions query
      const transactionsQuery = query(
        collection(db, 'transactions'),
        where('userId', '==', userId),
        orderBy('timestamp', 'desc'),
        limit(20)
      );

      // Money requests query
      const requestsQuery = query(
        collection(db, 'moneyRequests'),
        where('fromUserId', '==', userId),
        where('status', '==', 'pending'),
        orderBy('timestamp', 'desc')
      );

      // Subscribe to transactions
      const unsubscribeTransactions = onSnapshot(
        transactionsQuery, 
        (snapshot) => {
          const transactionsData: Transaction[] = [];
          snapshot.forEach((doc) => {
            const data = doc.data();
            if (data.type && typeof data.amount === 'number') {
              transactionsData.push({
                id: doc.id,
                type: data.type as Transaction['type'],
                amount: data.amount,
                recipientEmail: data.recipientEmail,
                senderEmail: data.senderEmail,
                status: data.status,
                timestamp: data.timestamp || null
              });
            }
          });
          setTransactions(transactionsData);
          setLoading(false);
        },
        (error) => {
          console.error("Error fetching transactions:", error);
          setError("Error fetching transactions. Please try again later.");
          setLoading(false);
        }
      );

      unsubscribers.push(unsubscribeTransactions);

      // Subscribe to pending requests
      const unsubscribePendingRequests = onSnapshot(
        requestsQuery,
        (snapshot) => {
          const requestsData: Transaction[] = [];
          snapshot.forEach((doc) => {
            const data = doc.data();
            if (typeof data.amount === 'number' && data.requesterEmail) {
              requestsData.push({
                id: doc.id,
                type: 'request',
                amount: data.amount,
                fromEmail: data.requesterEmail,
                status: 'pending',
                timestamp: data.timestamp || null
              });
            }
          });
          setPendingRequests(requestsData);
        },
        (error) => {
          if (error.code === 'failed-precondition') {
            // Collection doesn't exist yet, which is normal for new users
            setPendingRequests([]);
          } else {
            console.error("Error fetching pending requests:", error);
            setError("Error fetching requests. Please try again later.");
          }
        }
      );

      unsubscribers.push(unsubscribePendingRequests);
    } catch (error) {
      console.error("Error setting up listeners:", error);
      setError("Error loading transaction data. Please try again later.");
      setLoading(false);
    }

    return () => {
      unsubscribers.forEach(unsubscribe => unsubscribe());
    };
  }, [userId]);

  const formatTimestamp = (timestamp: Timestamp | null): string => {
    if (!timestamp) return 'Just now';

    const date = timestamp.toDate();
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
      return `Today at ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
    } else if (date.toDateString() === yesterday.toDateString()) {
      return `Yesterday at ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
    } else {
      return date.toLocaleDateString([], { 
        month: 'short', 
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    }
  };

  const TransactionIcon = ({ type }: { type: Transaction['type'] }) => {
    switch (type) {
      case 'send':
        return <ArrowUpRight className="text-red-500" />;
      case 'receive':
        return <ArrowDownLeft className="text-green-500" />;
      case 'request':
        return <Clock className="text-yellow-500" />;
      default:
        return null;
    }
  };

  const renderTransactionDetails = (transaction: Transaction): JSX.Element => {
    const email = transaction.type === 'send' 
      ? transaction.recipientEmail 
      : transaction.type === 'receive' 
        ? transaction.senderEmail 
        : transaction.fromEmail;

    return (
      <div className="flex flex-col">
        <span className="font-medium">
          {transaction.type === 'send' 
            ? `Sent to ${email}` 
            : transaction.type === 'receive' 
              ? `Received from ${email}`
              : `Requested from ${email}`}
        </span>
        <span className="text-sm text-gray-400">
          {formatTimestamp(transaction.timestamp)}
        </span>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-500/10 border border-red-500 text-red-500 px-4 py-3 rounded">
        {error}
      </div>
    );
  }

  return (
    <div className="mt-8 space-y-6">
      {pendingRequests.length > 0 && (
        <div>
          <h3 className="text-xl font-bold mb-4">Pending Requests</h3>
          <ul className="space-y-3">
            {pendingRequests.map((request) => (
              <li 
                key={request.id} 
                className="bg-gray-800/50 p-4 rounded-lg border border-gray-700 flex justify-between items-center"
              >
                <div className="flex items-center space-x-3">
                  <Clock className="text-yellow-500" />
                  <div>
                    <p>{request.fromEmail} requested</p>
                    <p className="text-lg font-semibold">${request.amount.toFixed(2)}</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}

      <div>
        <h3 className="text-xl font-bold mb-4">Transaction History</h3>
        {transactions.length === 0 ? (
          <div className="text-center py-8 text-gray-400">
            <p>No transactions yet.</p>
            <p className="text-sm">Your transaction history will appear here.</p>
          </div>
        ) : (
          <ul className="space-y-3">
            {transactions.map((transaction) => (
              <li 
                key={transaction.id}
                className="bg-gray-800/50 p-4 rounded-lg border border-gray-700 flex justify-between items-center"
              >
                <div className="flex items-center space-x-3">
                  <TransactionIcon type={transaction.type} />
                  {renderTransactionDetails(transaction)}
                </div>
                <span className={`font-semibold ${
                  transaction.type === 'send' 
                    ? 'text-red-500' 
                    : transaction.type === 'receive' 
                      ? 'text-green-500'
                      : 'text-yellow-500'
                }`}>
                  {transaction.type === 'send' ? '-' : transaction.type === 'receive' ? '+' : ''}
                  ${transaction.amount.toFixed(2)}
                </span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default TransactionHistory;