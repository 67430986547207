import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, onSnapshot } from 'firebase/firestore';
import { auth, db } from './firebase';
import { Home, Activity, User, Settings as SettingsIcon } from 'lucide-react';
import Header from './components/Header';
import Balance from './components/Balance';
import Actions from './components/Actions';
import TransactionHistory from './components/TransactionHistory';
import Profile from './components/Profile';
import Settings from './components/Settings';
import SignIn from './components/SignIn';
import BusinessDashboard from './components/restaurant/BusinessDashboard';
import MenuManager from './components/restaurant/MenuManager';
import RestaurantSearch from './components/restaurant/RestaurantSearch';

interface User {
  uid: string;
  email: string | null;
  userType?: 'Individual' | 'Business';
}

function MenuManagerWrapper() {
  const { id } = useParams();
  return <MenuManager restaurantId={id || ''} />;
}

function MainContent() {
  const [balance, setBalance] = useState(0);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        const userDocRef = doc(db, 'users', currentUser.uid);
        const unsubscribeUser = onSnapshot(userDocRef, (doc) => {
          if (doc.exists()) {
            const userData = doc.data();
            setUser({
              uid: currentUser.uid,
              email: currentUser.email,
              userType: userData.userType
            });
            setBalance(userData.balance || 0);
          }
        });
        setLoading(false);
        return () => unsubscribeUser();
      } else {
        setUser(null);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen bg-clkk-dark text-white flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-clkk-blue"></div>
      </div>
    );
  }

  if (!user && location.pathname !== '/signin') {
    return <Navigate to="/signin" replace />;
  }

  return (
    <div className="min-h-screen bg-clkk-dark text-white flex flex-col">
      <Header userType={user?.userType} />
      <main className="flex-grow container mx-auto px-4 py-8 pb-24">
        <Routes>
          <Route path="/" element={
            <>
              <Balance balance={balance} />
              <Actions 
                onSend={async (amount, recipientEmail) => {
                  // Handle send money
                }} 
                onRequest={async (amount, fromEmail) => {
                  // Handle request money
                }} 
                currentUserEmail={user?.email || ''}
              />
              <TransactionHistory userId={user?.uid || ''} />
            </>
          } />
          <Route path="/activity" element={<TransactionHistory userId={user?.uid || ''} />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/business" element={<BusinessDashboard />} />
          <Route path="/restaurant/:id/manage" element={<MenuManagerWrapper />} />
          <Route path="/restaurant/search" element={<RestaurantSearch />} />
        </Routes>
      </main>
      {user && (
        <nav className="fixed bottom-0 left-0 right-0 bg-clkk-dark border-t border-clkk-gray-800 backdrop-blur-sm bg-opacity-90">
          <ul className="flex justify-around max-w-lg mx-auto">
            <li>
              <button 
                onClick={() => navigate('/')} 
                className={`p-4 flex flex-col items-center min-w-[4rem] ${
                  location.pathname === '/' 
                    ? 'text-transparent bg-gradient-brand bg-clip-text' 
                    : 'text-gray-400 hover:text-gray-300'
                }`}
              >
                <Home size={20} className="mb-1" />
                <span className="text-xs">Home</span>
              </button>
            </li>
            <li>
              <button 
                onClick={() => navigate('/activity')} 
                className={`p-4 flex flex-col items-center min-w-[4rem] ${
                  location.pathname === '/activity' 
                    ? 'text-transparent bg-gradient-brand bg-clip-text' 
                    : 'text-gray-400 hover:text-gray-300'
                }`}
              >
                <Activity size={20} className="mb-1" />
                <span className="text-xs">Activity</span>
              </button>
            </li>
            <li>
              <button 
                onClick={() => navigate('/profile')} 
                className={`p-4 flex flex-col items-center min-w-[4rem] ${
                  location.pathname === '/profile' 
                    ? 'text-transparent bg-gradient-brand bg-clip-text' 
                    : 'text-gray-400 hover:text-gray-300'
                }`}
              >
                <User size={20} className="mb-1" />
                <span className="text-xs">Profile</span>
              </button>
            </li>
            <li>
              <button 
                onClick={() => navigate('/settings')} 
                className={`p-4 flex flex-col items-center min-w-[4rem] ${
                  location.pathname === '/settings' 
                    ? 'text-transparent bg-gradient-brand bg-clip-text' 
                    : 'text-gray-400 hover:text-gray-300'
                }`}
              >
                <SettingsIcon size={20} className="mb-1" />
                <span className="text-xs">Settings</span>
              </button>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/signin" element={<SignIn />} />
        <Route path="/*" element={<MainContent />} />
      </Routes>
    </Router>
  );
}

export default App;