import React, { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot, doc, updateDoc, deleteDoc, addDoc } from 'firebase/firestore';
import { db, auth } from '../../firebase';
import { MenuItem } from '../../types/restaurant';
import toast from 'react-hot-toast';
import { PlusCircle, Edit2, Trash2, CheckCircle, XCircle } from 'lucide-react';

interface MenuManagerProps {
  restaurantId: string;
}

const MenuManager: React.FC<MenuManagerProps> = ({ restaurantId }) => {
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [editingItem, setEditingItem] = useState<MenuItem | null>(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: '',
    category: '',
    isAvailable: true
  });

  useEffect(() => {
    if (!auth.currentUser || !restaurantId) {
      setLoading(false);
      return;
    }

    try {
      // Create a reference to the menuItems subcollection using the correct path
      const menuItemsRef = collection(db, `restaurants/${restaurantId}/menuItems`);
      const q = query(menuItemsRef, where('isAvailable', '==', true));

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const items: MenuItem[] = [];
        snapshot.forEach((doc) => {
          items.push({ id: doc.id, ...doc.data() } as MenuItem);
        });
        setMenuItems(items);
        setLoading(false);
      }, (error) => {
        console.error('Error fetching menu items:', error);
        toast.error('Failed to load menu items');
        setLoading(false);
      });

      return () => unsubscribe();
    } catch (error) {
      console.error('Error setting up menu items listener:', error);
      toast.error('Failed to load menu items');
      setLoading(false);
    }
  }, [restaurantId]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!auth.currentUser || !restaurantId) return;

    try {
      const itemData = {
        ...formData,
        price: parseFloat(formData.price),
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      };

      const menuItemsRef = collection(db, `restaurants/${restaurantId}/menuItems`);

      if (editingItem) {
        const itemRef = doc(menuItemsRef, editingItem.id);
        await updateDoc(itemRef, itemData);
        toast.success('Menu item updated successfully!');
      } else {
        await addDoc(menuItemsRef, itemData);
        toast.success('Menu item added successfully!');
      }

      setFormData({
        name: '',
        description: '',
        price: '',
        category: '',
        isAvailable: true
      });
      setEditingItem(null);
      setShowAddForm(false);
    } catch (error) {
      console.error('Error managing menu item:', error);
      toast.error('Failed to manage menu item. Please try again.');
    }
  };

  const handleDelete = async (itemId: string) => {
    if (!confirm('Are you sure you want to delete this item?')) return;

    try {
      const itemRef = doc(db, `restaurants/${restaurantId}/menuItems`, itemId);
      await deleteDoc(itemRef);
      toast.success('Menu item deleted successfully!');
    } catch (error) {
      console.error('Error deleting menu item:', error);
      toast.error('Failed to delete menu item. Please try again.');
    }
  };

  const handleEdit = (item: MenuItem) => {
    setEditingItem(item);
    setFormData({
      name: item.name,
      description: item.description,
      price: item.price.toString(),
      category: item.category,
      isAvailable: item.isAvailable
    });
    setShowAddForm(true);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">Menu Manager</h2>
        <button
          onClick={() => setShowAddForm(!showAddForm)}
          className="flex items-center space-x-2 bg-gradient-brand hover:opacity-90 text-white px-4 py-2 rounded"
        >
          <PlusCircle size={20} />
          <span>Add Item</span>
        </button>
      </div>

      {showAddForm && (
        <form onSubmit={handleSubmit} className="bg-gray-800 p-6 rounded-lg mb-6 space-y-4">
          <div>
            <label className="block text-sm font-medium mb-1">Name</label>
            <input
              type="text"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              required
              className="w-full p-2 rounded bg-gray-700 border border-gray-600 focus:border-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Description</label>
            <textarea
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              required
              rows={3}
              className="w-full p-2 rounded bg-gray-700 border border-gray-600 focus:border-blue-500"
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium mb-1">Price</label>
              <input
                type="number"
                step="0.01"
                min="0"
                value={formData.price}
                onChange={(e) => setFormData({ ...formData, price: e.target.value })}
                required
                className="w-full p-2 rounded bg-gray-700 border border-gray-600 focus:border-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-1">Category</label>
              <input
                type="text"
                value={formData.category}
                onChange={(e) => setFormData({ ...formData, category: e.target.value })}
                required
                className="w-full p-2 rounded bg-gray-700 border border-gray-600 focus:border-blue-500"
              />
            </div>
          </div>

          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={formData.isAvailable}
              onChange={(e) => setFormData({ ...formData, isAvailable: e.target.checked })}
              className="rounded border-gray-600"
            />
            <label className="text-sm font-medium">Available</label>
          </div>

          <div className="flex space-x-4">
            <button
              type="submit"
              className="flex-1 bg-gradient-brand hover:opacity-90 text-white px-4 py-2 rounded"
            >
              {editingItem ? 'Update Item' : 'Add Item'}
            </button>
            <button
              type="button"
              onClick={() => {
                setShowAddForm(false);
                setEditingItem(null);
                setFormData({
                  name: '',
                  description: '',
                  price: '',
                  category: '',
                  isAvailable: true
                });
              }}
              className="flex-1 bg-gray-600 hover:bg-gray-700 text-white px-4 py-2 rounded"
            >
              Cancel
            </button>
          </div>
        </form>
      )}

      <div className="space-y-4">
        {menuItems.length === 0 ? (
          <div className="text-center py-8 text-gray-400">
            <p>No menu items yet.</p>
            <p className="text-sm">Click "Add Item" to create your first menu item.</p>
          </div>
        ) : (
          menuItems.map((item) => (
            <div
              key={item.id}
              className="bg-gray-800 p-4 rounded-lg flex items-center justify-between"
            >
              <div className="flex-1">
                <h3 className="font-medium">{item.name}</h3>
                <p className="text-sm text-gray-400">{item.description}</p>
                <div className="flex items-center space-x-4 mt-2">
                  <span className="text-sm font-medium">${item.price.toFixed(2)}</span>
                  <span className="text-sm text-gray-400">{item.category}</span>
                  {item.isAvailable ? (
                    <span className="flex items-center text-green-500 text-sm">
                      <CheckCircle size={16} className="mr-1" />
                      Available
                    </span>
                  ) : (
                    <span className="flex items-center text-red-500 text-sm">
                      <XCircle size={16} className="mr-1" />
                      Unavailable
                    </span>
                  )}
                </div>
              </div>
              <div className="flex space-x-2">
                <button
                  onClick={() => handleEdit(item)}
                  className="p-2 hover:bg-gray-700 rounded"
                >
                  <Edit2 size={20} />
                </button>
                <button
                  onClick={() => handleDelete(item.id)}
                  className="p-2 hover:bg-gray-700 rounded text-red-500"
                >
                  <Trash2 size={20} />
                </button>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default MenuManager;