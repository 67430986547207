import React from 'react';
import { Zap, Store } from 'lucide-react';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

interface HeaderProps {
  userType?: 'Individual' | 'Business';
}

const Header: React.FC<HeaderProps> = ({ userType }) => {
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/signin');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <header className="sticky top-0 z-50 bg-clkk-dark border-b border-clkk-gray-800 p-4 flex justify-between items-center shadow-lg backdrop-blur-sm">
      <button 
        onClick={() => navigate('/')} 
        className="flex items-center space-x-2 hover:opacity-90 transition-opacity"
      >
        <Zap className="w-8 h-8 text-transparent bg-gradient-brand bg-clip-text" />
        <h1 className="text-2xl font-bold text-transparent bg-gradient-brand bg-clip-text">CLKK</h1>
      </button>
      <div className="flex items-center space-x-4">
        {userType === 'Business' && (
          <button 
            onClick={() => navigate('/business')} 
            className="flex items-center space-x-2 px-4 py-2 rounded-lg hover:bg-clkk-gray-800 transition-colors"
          >
            <Store size={20} />
            <span>Manage Business</span>
          </button>
        )}
        {auth.currentUser ? (
          <button 
            onClick={handleSignOut} 
            className="px-4 py-2 rounded-lg bg-gradient-brand hover:opacity-90 transition-opacity text-sm font-medium"
          >
            Sign Out
          </button>
        ) : (
          <button 
            onClick={() => navigate('/signin')} 
            className="px-4 py-2 rounded-lg bg-gradient-brand hover:opacity-90 transition-opacity text-sm font-medium"
          >
            Sign In
          </button>
        )}
      </div>
    </header>
  );
};

export default Header;