import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);

// Initialize required collections if they don't exist
export const initializeCollections = async () => {
  // Only attempt to initialize if user is authenticated
  if (!auth.currentUser) {
    console.log('User must be authenticated to initialize collections');
    return;
  }

  try {
    // We don't need to create documents, just verify collections exist
    // Collections are automatically created when the first document is added
    const collections = ['transactions', 'moneyRequests', 'users'];
    
    for (const collectionName of collections) {
      const collRef = collection(db, collectionName);
      await getDocs(collRef).catch(error => {
        if (error.code === 'permission-denied') {
          console.log(`Collection ${collectionName} will be created when needed`);
        }
      });
    }
  } catch (error) {
    // Log error but don't throw - collections will be created when needed
    console.log('Collections will be created when needed:', error);
  }
};

// Initialize collections when auth state changes to signed in
auth.onAuthStateChanged((user) => {
  if (user) {
    // Delay initialization slightly to ensure auth token is set
    setTimeout(() => {
      initializeCollections();
    }, 1000);
    console.log('User is signed in');
  } else {
    console.log('No user is signed in');
  }
}, (error) => {
  console.error('Auth state change error:', error);
});