import React, { useState } from 'react';
import { auth } from '../firebase';
import { sendPasswordResetEmail } from 'firebase/auth';

const SecuritySettings: React.FC = () => {
  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleResetPassword = async () => {
    setMessage(null);
    setError(null);

    if (!auth.currentUser?.email) {
      setError("No email associated with the current user.");
      return;
    }

    try {
      await sendPasswordResetEmail(auth, auth.currentUser.email);
      setMessage("Password reset email sent. Please check your inbox.");
    } catch (err) {
      setError("Failed to send password reset email. Please try again later.");
      console.error(err);
    }
  };

  return (
    <div className="mt-6 bg-gray-800 p-4 rounded">
      <h3 className="text-xl font-semibold mb-4">Security Settings</h3>
      <div>
        <button
          onClick={handleResetPassword}
          className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Reset Password
        </button>
        {message && <p className="text-green-500 mt-2">{message}</p>}
        {error && <p className="text-red-500 mt-2">{error}</p>}
      </div>
    </div>
  );
};

export default SecuritySettings;